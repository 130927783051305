// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

import "../stylesheets/top.scss";

// init Swiper:
const swiper = new Swiper(".top-swiper", {
  loop: true,
  speed: 1500,
  autoplay: {
    delay: 8000,
    disableOnInteraction: false,
  },
  allowTouchMove: false,
});
